import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bf88a24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collections pt-6" }
const _hoisted_2 = { class: "page-container" }
const _hoisted_3 = {
  key: 0,
  class: "collections-block"
}
const _hoisted_4 = { class: "collections-columns columns" }
const _hoisted_5 = { class: "py-2" }
const _hoisted_6 = {
  key: 0,
  class: "is-flex is-justify-content-center mt-5"
}
const _hoisted_7 = ["disabled"]
const _hoisted_8 = {
  key: 0,
  class: "fas fa-chevron-down"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionBlock = _resolveComponent("CollectionBlock")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_SpinnerContainer = _resolveComponent("SpinnerContainer")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.initLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.collections, (collection) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "column is-half-tablet is-one-quarter-desktop",
                  key: collection['@id']
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_CollectionBlock, { collection: collection }, null, 8, ["collection"])
                  ])
                ]))
              }), 128))
            ]),
            (_ctx.canLoadMore || _ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("button", {
                    disabled: _ctx.loading,
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadMore && _ctx.loadMore(...args))),
                    class: "btn gossamer p-5"
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("search.show-more")), 1),
                    (!_ctx.loading)
                      ? (_openBlock(), _createElementBlock("i", _hoisted_8))
                      : _createCommentVNode("", true),
                    (_ctx.loading)
                      ? (_openBlock(), _createBlock(_component_Spinner, {
                          key: 1,
                          size: "sm"
                        }))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_7)
                ]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createBlock(_component_SpinnerContainer, { key: 1 }))
    ])
  ]))
}