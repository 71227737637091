
import { defineComponent } from "vue";
import CollectionBlock from "../../../shared/components/CollectionBlock.vue";
import SpinnerContainer from "../../../shared/components/SpinnerContainer.vue";
import Spinner from "../../../shared/components/Spinner.vue";
import { useUserCollectionsSearch } from "@kunsten/core";

export default defineComponent({
    name: "Collections",
    components: { CollectionBlock, SpinnerContainer, Spinner },
    setup() {
        const { results, loading, initLoading, canLoadMore, loadMore } = useUserCollectionsSearch();

        return { collections: results, loading, initLoading, canLoadMore, loadMore };
    },
});
